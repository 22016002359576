.mobileStoreDashboardLayout {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern, readable font */
  background-color: #f9f9f9; /* Slightly off-white background for the entire layout */
  color: #333; /* Dark grey text color for better contrast */
}

.mobileNavigation {
  background: linear-gradient(135deg, #0066cc, #003d80); /* Slightly lighter gradient for better contrast */
  color: #fff; /* White text for better contrast */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow for more depth */
  padding: 10px 0; /* Slightly increased padding for better touchability */
  border-radius: 0 0 10px 10px; /* Rounded bottom corners for a softer look */
}

.mobileNavigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; /* Center navigation items horizontally */
  align-items: center; /* Vertically align items */
}

.mobileNavigation li {
  padding: 10px 15px; /* Reduced padding for closer icons */
  cursor: pointer; /* Pointer cursor for clickable items */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for feedback */
  color: #fff; /* White text color */
  font-size: 1rem; /* Slightly larger text for readability */
  border-radius: 5px; /* Rounded edges for a more modern look */
  display: flex; /* Ensure the icon stays centered */
  align-items: center; /* Vertically center the icon */
  justify-content: center; /* Horizontally center the icon */
}

.mobileNavigation li:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
  color: #ffdd57; /* Gold color for better visibility on hover */
}

.mobileMainContent {
  flex-grow: 1; /* Allows main content to take up remaining space */
  overflow-y: auto; /* Enables scrolling for main content area if content overflows */
  padding: 20px;
  background-color: #f2f2f2; /* Light grey background for readability */
  transition: background-color 0.3s; /* Smooth transition for any background color change */
  border-radius: 10px; /* Rounded corners for the content area */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for content depth */
  margin: 10px; /* Margin to provide breathing room around the content area */
}

@media (max-width: 600px) {
  .mobileNavigation li {
    padding: 10px 12px; /* Further reduced padding for closer icons on smaller screens */
    font-size: 0.9rem; /* Adjust text size for smaller screens */
  }
}
