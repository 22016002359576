.loading-indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This assumes you want to take up the full viewport height */
  width: 100vw; /* This assumes you want to take up the full viewport width */
  position: fixed; /* Overlay on top of your content */
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Optional: background overlay */
  z-index: 9999; /* Ensure it's above other content */
}

.logoWrapper {
  display: flex;
  justify-content: center;
}

.logo {
  width: 100px; /* Adjust size as needed */
  height: auto;
  animation: rotateLogo 2s linear infinite;
}

@keyframes rotateLogo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}