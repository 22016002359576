/* File: src/components/ProlifiField/Schedules/WeekRangeSelector.css */

/* Container: a card-like block with subtle shadow */
.wrs-container {
  padding: 1rem;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
  max-width: 320px;
  margin: 0 auto; /* Center horizontally */
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* Title: clear, bold */
.wrs-title {
  margin: 0 0 0.65rem;
  font-size: 1.15rem;
  color: #333;
  font-weight: 600;
}

/* 
 * The new "Mark Week Complete" button:
 * - Minimal fill, subtle border, modern shape
 * - Smooth hover/focus transitions
 */
.wrs-mark-complete {
  display: inline-block;
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #1976d2;
  background-color: transparent;
  border: 1px solid #1976d2;
  padding: 3px 8px;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;
}

.wrs-mark-complete:hover {
  background-color: #1976d2;
  color: #fff;
}

.wrs-mark-complete:focus {
  outline: 2px solid #90caf9; /* Lighter blue ring on focus */
  outline-offset: 2px;
}

/* Row => aligns arrow buttons and label side-by-side */
.wrs-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.75rem;
}

/* The left/right arrow buttons */
.wrs-arrow-btn {
  cursor: pointer;
  border: none;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 1rem;
  font-weight: 500;
  background-color: #1976d2;
  color: #fff;
  transition: background-color 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.wrs-arrow-btn:hover {
  background-color: #1565c0;
}

.wrs-arrow-btn:focus {
  outline: 2px solid #90caf9;
  outline-offset: 2px;
}

/* The label that shows e.g. "3/06 - 3/12" */
.wrs-label {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  min-width: 120px;
  text-align: center;
}

/* The aggregator row => shows "This Week's Hours: XX" */
.wrs-overall-row {
  margin-top: 0.5rem;
  font-size: 0.95rem;
  color: #333;
}

/* Aggregator => normal style */
.wrs-aggregator {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
}

/* If we want a red alert style when below threshold (e.g. 45 hours) */
.wrs-aggregator-alert {
  color: #e53935;
  border: 1px solid #e53935;
  box-shadow: 0 0 6px 2px rgba(229, 57, 53, 0.3);
}
.wrs-complete-disabled {
  background-color: #4caf50; /* a pleasant green */
  color: #ffffff;           /* white text */
  border: 1px solid #4caf50;
  cursor: default;
}

.wrs-complete-disabled:hover {
  background-color: #4caf50; /* no hover effect */
}

.wrs-complete-disabled:focus {
  outline: 2px solid #8bc34a; /* slightly lighter green focus ring */
  outline-offset: 2px;
}