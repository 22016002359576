/* ResetPassword.module.css */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.form {
  padding: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin: 0 20px;
}

.input {
  width: 92%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.button {
  width: 100%;
  padding: 15px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: #004085;
}

.errorMessage {
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 600px) {
  .form {
    margin: 0 10px;
    padding: 20px;
  }

  .input, .button {
    padding: 10px;
  }
}
