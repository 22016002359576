/* Global Styles */
body, html {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;
  background: #f4f4f4;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.navbar {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
}

.nav-link {
  color: #007bff;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #0056b3;
}

.hero-section {
  background-color: #007bff;
  color: white;
  padding: 60px 0;
}

.service-card {
  background: white;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
}

.about-section {
  padding: 40px 0;
}

.text-center {
  text-align: center;
}
