/* src/components/ClosingCheckListBuilder.module.css */
.container {
  padding: 20px;
}

.newSection {
  margin-bottom: 20px;
}

.input {
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}

.section {
  margin-bottom: 30px;
  padding: 10px;
}

.sectionHeader {
  margin-bottom: 10px;
  cursor: move;
}

.section h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.addButton {
  display: block;
  margin-top: 10px;
}

.submitButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.draggableItem {
  margin-bottom: 10px;
  cursor: move;
}

.dragIcon {
  margin-right: 8px;
  cursor: move;
}
