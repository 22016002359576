/* TZFileUpload.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: auto;
  max-width: 500px;
}

.fileInput {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.tzUploadButton {
  width: 200px; /* Adjust the width as needed */
  padding: 3px 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.tzUploadButton:hover {
  background-color: #0056b3;
}

.tzUploadButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


.message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #0056b3;
}

.error {
  color: red;
}

.success {
  color: green;
}
