/* CorrectionActionForm.module.css */

.formContainer {
  margin-top: 50px;
  padding: 20px;
  background-color: #00000000;
  border-radius: 8px;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.formGroup input[type="text"],
.formGroup textarea,
.formGroup select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.formGroup input[type="text"]:focus,
.formGroup textarea:focus,
.formGroup select:focus {
  border-color: #007bff;
}

.warningGroup {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  text-align: center;
}

.customRadio {
  display: none;
}

.customRadio + label {
  position: relative;
  padding-left: 25px; /* Reduced padding */
  cursor: pointer;
  color: #020202;
  font-weight: 600;
  font-size: 0.9rem; /* Smaller font size */
}

.customRadio + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px; /* Reduced size */
  height: 20px; /* Reduced size */
  border-radius: 50%;
  background: #f1f1f1;
  border: 2px solid #007bff;
  transition: background 0.3s ease-in-out;
}

.customRadio:checked + label:before {
  background: #007bff;
}

.customRadio:checked + label:after {
  content: '';
  position: absolute;
  left: 6px; /* Adjusted position */
  top: 4px; /* Adjusted position */
  width: 8px; /* Reduced size */
  height: 12px; /* Reduced size */
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input[type="radio"] + label:hover:before,
input[type="radio"]:checked + label:hover:before {
  border-color: #0056b3;
}

button[type="submit"] {
  background-color: #007bff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Additional class for input fields in the same line */
.inline-fields {
  display: flex;
  gap: 1rem;
}

.inline-fields .formGroup {
  flex: 1;
}

.signature-container {
  margin-top: 20px;
  Width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signature-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.signature-pad {
  border: 2px solid #007bff; /* More visible border */
  border-radius: 4px;
  width: 100%; /* Full width */
  max-width: 550px; /* Set a maximum width */
  height: 200px; /* Increased height */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a slight shadow for better visibility */
}

.centered-label {
  text-align: center;
  width: 100%;
}
