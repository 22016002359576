.cash-count-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: auto;
  padding: 20px;
  max-width: 800px; /* Adjust based on your layout preference */
}

.section {
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Light grey border */
  padding: 15px; /* Add some padding inside the sections */
  border-radius: 8px; /* Optional: Adds rounded corners for a softer look */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
}

.cash-count-container h1 {
  margin-top: -20px;
  font-size: 20px; /* Smaller font size for the main title */
  margin-bottom: 15px;
  color: #333; /* Dark text for better readability */
  text-align: center; /* Ensure title is centered */
}

.cash-count-container  h3 {
  margin-bottom: -25px;
  color: #333; /* Dark text for better readability */
  font-size: 16px;
}

.section h3 {
  margin-bottom: 15px;
  color: #333; /* Dark text for better readability */
}

.currency-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Adjusted gap for clarity */
}

.grouped-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 20px;
  width: 100%;
}

.denomination-label {
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}

.input-group {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
}

.primary-input, .secondary-input {
  display: flex;
  flex-direction: column;
  flex: 1; /* Enable inputs within a group to fill the container evenly */
  min-width: 120px; /* Minimum width to ensure readability */
}

.primary-input label, .secondary-input label {
  font-weight: bold;
  margin-bottom: 5px;
}

.primary-input input, .secondary-input input {
  padding: 8px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px; /* Slightly rounded corners for a modern look */
  text-align: center;
}

.toggle-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.toggle-btn {
  background-color: #ccc; /* Default background, not visible when one of the options is selected */
  border-radius: 20px;
  cursor: pointer;
  padding: 5px;
  width: 75px;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.3s;
}

.toggle-btn-circle {
  background-color: white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  transition: all 0.3s;
}

.toggle-label {
  position: absolute;
  color: white;
  font-weight: bold;
}

/* Bills selected: Apply a shade of blue */
.toggle-btn:not(.toggle-right) {
  background-color: #007bff; /* Blue for Bills */
}

/* Coins selected: Apply a different shade of blue */
.toggle-btn.toggle-right {
  background-color: #0056b3; /* A different shade of blue for Coins */
  justify-content: flex-end;
}

/* Adjust label position and color based on the section */
.toggle-btn:not(.toggle-right) .toggle-label {
  right: 10px;
  color: #fff; /* White color for better contrast */
}

.toggle-btn.toggle-right .toggle-label {
  left: 10px;
  color: #fff; /* White color for better contrast */
}

/* Verification Section Styling */
.section.verification {
  background-color: #f7f7f7; /* Light background to differentiate the section */
  border: 1px solid #e1e1e1; /* Slight border for definition */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Inner spacing */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  margin-top: 5px; /* Space from previous section */
}

/* Styling for checkbox labels for better readability */
.section.verification label {
  display: block; /* Make each label take its own line */
  margin: 5px 0; /* Spacing between checkbox options */
  cursor: pointer; /* Indicates the label is clickable */
  font-size: 12px; /* Adjust based on your preference */
}

/* Total Counted Display Styling */
.total-counted {
  font-weight: bold;
  margin-top: 8px; /* Space from checkboxes */
}

/* Submit Button Styling */
.submit-btn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for button size */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners for the button */
  cursor: pointer; /* Indicates the button is clickable */
  margin-top: 10px; /* Space from total counted display */
  display: inline-block; /* Allows setting margins */
  font-size: 16px; /* Button text size */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.submit-btn:hover {
  background-color: #45a049; /* Darker green on hover */
}

.verification-question {
  margin-bottom: 5px;
}

.checkbox-group label {
  margin-right: 20px; /* Space out the yes/no options */
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 5px; /* Space between checkbox and label */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.codeInputContainer {
  display: flex;
  justify-content: space-between;
  max-width: 200px; /* Adjust based on your design */
  margin: auto;
}

.codeInput {
  width: 8px; /* Adjust size as needed */
  height: 6px;
  space-between: 2px;
  text-align: center;
  font-size: 15px; /* Large font size for easy reading */
  padding: 10px;
  margin: 2px;
  border: 2px solid #ccc; /* Sleek border */
  border-radius: 5px; /* Rounded corners for modern look */
  transition: border-color 0.3s;
}

.codeInput:focus {
  border-color: #007bff; /* Highlight color when focused */
  outline: none;
}


/* Responsive adjustments for small screens */
@media (max-width: 600px) {
  .input-group {
    flex-direction: row; /* Adjust to row for side-by-side layout on small screens */
    flex-wrap: wrap; /* Allow wrapping if needed */
	margin-right: 20px;
  }

  .primary-input, .secondary-input {
    width: calc(25% - 5px); /* Adjust width to fit two inputs side by side */
    min-width: 0; /* Allow inputs to shrink on very small screens */
  }
  
  .grouped-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 20px;
  width: 100%;
}

}
