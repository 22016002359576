/* src/components/SessionChecker.css */
.session-checker {
  padding: 20px;
}

.session-checker h2 {
  margin-bottom: 20px;
}

.session-checker table {
  width: 100%;
  border-collapse: collapse;
}

.session-checker th, .session-checker td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.session-checker th {
  background-color: #2980b9;
}

.session-checker tr:nth-child(even) {
  background-color: #f9f9f9;
}

.session-checker tr:hover {
  background-color: #ddd;
}

.session-checker button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.session-checker button:hover {
  background-color: darkred;
}
