/* Container */
.cadContainer {
  font-family: Arial, sans-serif;
  max-width: 1200px; /* Increased width to accommodate all elements in one line */
  margin: 20px auto;
  text-align: center;
}

/* Form row */
.formRow {
  display: flex;
  flex-wrap: wrap; /* Allows elements to wrap if necessary */
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

/* Input field */
.inputField {
  flex: 1;
  min-width: 200px; /* Ensures input field is at least 200px wide */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Dropdown */
.dropdown {
  flex: 1;
  min-width: 200px; /* Ensures dropdown is at least 200px wide */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="none" stroke="%23000" stroke-width=".75" d="M0 0l2 2 2-2"/></svg>') no-repeat right center;
  background-size: 8px 10px;
}

/* Button */
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

/* Suggestions container */
.suggestionsContainer {
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: absolute;
  width: 100%; /* Make the suggestions container the same width as the input */
  z-index: 2;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

/* Suggestion item */
.suggestionItem {
  padding: 10px;
  cursor: pointer;
}

.suggestionItemHighlighted {
  background-color: #f0f0f0;
}

/* Suggestions list */
.suggestionsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Table */
.tableContainer {
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #0d6efd;
}

.table tbody {
  display: block;
  max-height: 800px; /* Fixed height for the table body */
  overflow-y: scroll;
  width: 100%;
}

.table thead, .table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Action Buttons */
.actionButtons {
  display: flex;
  gap: 5px;
}

.actionButton {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.actionButton:hover {
  opacity: 0.8;
}

.actionButton.pdf {
  background-color: #28a745;
  color: white;
}

.actionButton.delete {
  background-color: #dc3545;
  color: white;
}

/* Modal */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.confirmButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.confirmButton:hover {
  background-color: #c82333;
}

.cancelButton {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: #5a6268;
}