html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background-color: #001529;
  width: 102%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  flex: 1;
  width: 102%;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-section {
  margin-bottom: 10px;
}

.ant-table-cell {
  padding: 4px; /* Reduce padding around cells */
}

.ant-input-number,
.ant-input-number-input {
  width: 100%;
  box-sizing: border-box;
  padding: 0; /* Remove padding inside input */
  margin: 0; /* Remove margin inside input */
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield; /* Firefox */
}

.date-picker-wrapper {
  margin-left: auto;
}

.date-picker {
  width: 80px;
  height: 50px;
  padding: 2px;
}
