/* ReactDatePickerOverrides.css */

/* The main calendar popper */
.react-datepicker-popper {
  z-index: 9999; /* So it appears above other elements */
}

/* Overall container */
.react-datepicker {
  font-family: "Inter", sans-serif;
  border: 1px solid #e2e8f0; /* Chakra's gray.200 for light mode */
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0,0,0,0.08);
  background-color: #fff;  
}

/* Calendar header (month/year title area) */
.react-datepicker__header {
  background-color: #319795; /* e.g. Chakra's teal.500 */
  border-bottom: 1px solid transparent;
  border-radius: 8px 8px 0 0;
  padding-top: 8px;
  color: #fff;
}

/* Month/Year Title */
.react-datepicker__current-month {
  font-weight: 600;
  font-size: 1rem;
}

/* Day names row */
.react-datepicker__day-names {
  margin-top: 4px;
}

/* Individual Day cells */
.react-datepicker__day,
.react-datepicker__day-name {
  margin: 0.2rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
  color: #2d3748; /* Chakra's gray.700 */
}

/* Hover effect on days */
.react-datepicker__day:hover {
  background-color: #b2f5ea; /* teal.100 */
  color: #285e61; /* teal.800 */
}

/* Selected day styling */
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #319795; /* teal.500 */
  color: #fff;
}

/* Today’s highlight */
.react-datepicker__day--today {
  font-weight: bold;
  border: 1px dashed #319795;
}

/* Disable arrows if not applicable */
.react-datepicker__navigation--disabled {
  visibility: hidden;
}
