/* Example: MyScheduler.css */

.noMoreIndicator .e-schedule .e-month-view .e-content-table {
  /* Force the month rows to auto-expand */
  height: auto !important;
  overflow: visible !important;
}

.e-more-appointment-popup.e-popup {
  width: 600px !important;
  /* or .e-more-popup if your version uses that class */
}

.e-more-popup-wrapper.e-popup .e-appointment {
  white-space: normal !important;
  line-height: 1.1em !important;
  padding: 3px !important;
  min-height: 35px !important;
}

.e-toolbar-item.e-today {
  display: none !important;
}
