/* ./components/NotFound.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.container p {
  margin-bottom: 20px;
}

.container a {
  color: #0056b3;
  text-decoration: none;
}
