.closingCheckList {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 24px;
  max-width: 600px;
  margin: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.closingCheckList:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.title {
  text-align: center;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
}

.storeId {
  text-align: center;
  color: #666;
  margin-bottom: 24px;
}

.tabContainer {
  margin-bottom: 16px;
}

.tabs {
  background-color: #f5f5f5;
  border-radius: 8px;
}

.activeTab {
  background: linear-gradient(90deg, #667eea, #764ba2);
  color: #ffffff !important;
  border-radius: 8px;
}

.inactiveTab {
  color: #667eea !important;
}

.progressBar {
  margin: 20px 0;
  height: 8px;
  border-radius: 4px;
  background-color: #f0f0f0;
  & > .MuiLinearProgress-bar {
    background: linear-gradient(90deg, #667eea, #764ba2);
  }
}

.taskContainer {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.taskList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.taskItem {
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.2s, transform 0.2s;
}

.taskItem:hover {
  background-color: #f5f5f5;
  transform: translateX(4px);
}

.taskLabel {
  color: #333;
}

.checkbox {
  color: #667eea !important;
}

.submitButton {
  width: 100%;
  margin-top: 24px;
  padding: 12px 0;
  background-color: #667eea !important;
  color: #ffffff;
  font-weight: bold;
  text-transform: none;
  border-radius: 8px;
  transition: background-color 0.2s, transform 0.2s;
}

.submitButton:hover {
  background-color: #564aa3 !important;
  transform: translateY(-2px);
}

/* Overdue Task Styles */
.overdueTask {
  background-color: #ffeaea;
  border: 2px solid #ff4d4d;
}

.overdueLabel {
  color: #d32f2f;
  font-weight: bold;
}

.overdueTask:before {
  content: '⚠️ ';
  color: #d32f2f;
  font-size: 1.2em;
  margin-right: 8px;
}

/* Weekly Items Styles */
.subTaskList {
  list-style-type: none;
  padding: 0;
  margin: 12px 0 0 24px; /* Indentation to show it's part of the parent task */
}

.subTaskItem {
  background-color: #f0f4ff;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s, transform 0.2s;
}

/* Apply overdue styles to subTaskItem if the parent is overdue */
.subTaskItem.overdueTask {
  background-color: #ffeaea !important;
  border-left: 4px solid #ff4d4d;
}

.subTaskItem:hover {
  background-color: #e2e8ff;
  transform: translateX(4px);
}

.subTaskItem .MuiFormControlLabel-label {
  color: #4455aa;
  font-weight: 500;
}

.subTaskItem .MuiCheckbox-root {
  color: #4455aa !important;
}

.subTaskItem .MuiCheckbox-root.Mui-checked {
  color: #667eea !important;
}

/* Task Item with Sub-Items Styling */
.taskItem.hasSubItems {
  background-color: #f9f9f9;
  border-left: 4px solid #667eea;
  padding-left: 16px;
  margin-left: -4px;
  position: relative;
}

.taskItem.hasSubItems:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 50%;
  background-color: #667eea;
  transform: translateY(-50%);
}

/* Adjusting Task Label Styles */
.taskLabel {
  font-weight: bold;
  color: #333;
}

.taskLabel.subTaskLabel {
  font-weight: 500;
  color: #4455aa;
}
