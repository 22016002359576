/* Modern, minimal drag/drop style for the file upload */

.container {
  max-width: 600px;
  margin: 2rem auto;
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0,0,0,0.05);
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.title {
  color: #333;
  font-size: 1.75rem;
  margin-bottom: 0.1rem;
  text-align: center;
  font-weight: 600;
}

.instructions {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 1rem;
  text-align: center;
}

/* Controls row for month selector and checkbox side by side */
.controlsRow {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

/* Month Selector */
.monthSelector {
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.monthSelector label {
  font-weight: 500;
  color: #444;
  margin-bottom: 0.3rem;
  font-size: 0.85rem;
}

.e-datepicker,
.e-control-wrapper {
  width: 100% !important; /* ensure date picker grows to fill container */
}

/* Checkbox Container */
.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: -2px; /* nudge checkbox up a bit if needed */
}

.checkboxContainer input[type="checkbox"] {
  margin-right: 0.4rem;
  transform: scale(1.1);
  cursor: pointer;
}

.checkboxContainer label {
  font-weight: 500;
  color: #444;
  font-size: 0.85rem;
}

/* File Upload Section */
.uploadSection {
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
}

/* Our new drag-and-click area: a dashed rectangle */
.dragArea {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 2rem 1rem;
  cursor: pointer;
  transition: border-color 0.3s;
  color: #666;
  font-weight: 500;
  font-size: 0.95rem;
  background: #fafafa;
  width: 100%;
}

.dragArea:hover {
  border-color: #999;
}

/* The hidden file input */
.fileInput {
  display: none;
}

.errorMessage {
  margin-top: 0.5rem;
  color: #d9534f;
  font-weight: 600;
}

/* File Preview */
.filePreview {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 0.8rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: left;
}

.filePreview h3 {
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 0.4rem;
}

/* Button Container for Process File button */
.buttonContainer {
  text-align: center;
  margin-bottom: 1rem;
}

.uploadButton {
  background-color: #34a853;
  color: #fff;
  padding: 0.8rem 1.4rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.uploadButton:hover {
  background-color: #2c8e44;
}

/* Loading indicator section */
.loadingSection {
  text-align: center;
  margin: 1.5rem 0;
}

/* Result section */
.resultSection {
  background: #f1f9f3;
  border: 1px solid #cce5cc;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  margin-top: 1rem;
  text-align: center;
}

.resultSection h3 {
  margin: 0 0 0.3rem 0;
  color: #2c8e44;
  font-size: 1.1rem;
  font-weight: 600;
}

.resultSection p {
  margin: 0;
  font-size: 0.9rem;
}

.recentUpload {
  text-align: center;   /* Centers the text */
  font-size: 0.65rem;    /* Makes the text smaller; adjust as needed */
  margin: 1px 0;       /* Optional: adds vertical spacing */
}

/* New container for Totzone Emails */
.emailContainer {
  margin-top: 30px; /* Adds spacing between upload box and email container */
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Title for Totzone Emails */
.emailTitle {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Smaller subtitle above the buttons */
.emailSubTitle {
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

/* Container to hold the buttons side-by-side */
.emailButtons {
  display: flex;
  justify-content: center;
  gap: 15px; /* Adds horizontal spacing between buttons */
}

/* Individual button styles */
.emailButton {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.2s ease;
}

.emailButton:hover {
  background-color: #0056b3;
}
