.container {
  background-color: #fafafa; /* Slightly off-white for a modern light theme */
  color: #212121;            /* Dark gray text color */
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  font-family: "Roboto", sans-serif;
}

/* Large heading, center-aligned, stronger color contrast */
.overviewHeader {
  font-size: 2rem;     /* MUI often uses rem-based font sizes */
  font-weight: 600;
  margin-bottom: 24px; /* Slightly larger spacing */
  text-align: center;
  color: #1e1e1e;
}

.loadingSection {
  margin-top: 60px;
  text-align: center;
}

.error {
  color: #d32f2f;         /* MUI error color */
  background-color: #fdecea;
  padding: 12px;
  border-radius: 6px;
  margin: 12px 0;
}

.noData {
  color: #757575;
  text-align: center;
  margin-top: 50px;
  font-style: italic;
}

/* Store card with subtle shadow on a light background */
.storeItem {
  margin-bottom: 30px;
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.08); /* softer shadow */
  transition: box-shadow 0.2s ease;
}

.storeItem:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.12); /* slightly deeper on hover */
}

/* Center store name in the card */
.centeredStoreName {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 0 6px;
  color: #424242;
}

/* Subtitle (timestamp) under the store name */
.storeSubTitle {
  font-size: 0.95rem;
  color: #757575;
  text-align: center;
  margin: 0 0 14px; /* consistent spacing */
}

/* Slight spacing above the content area */
.currentMonthObjectives {
  margin-top: 10px;
}

/* Layout row: big circles on left, vertical divider, historical on right */
.contentRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px; /* MUI often uses 8px multiples - so 8, 16, 20, 24, etc. */
}

.objectivesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}

/* Each objective in a flex column to center label + circle */
.objectiveWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Label above the big circle */
.objLabel {
  font-size: 0.9rem;
  margin-bottom: 6px;
  color: #555;
}

/* This wraps the large <CircularProgressbar> for each objective */
.bigCircle {
  width: 100px;  /* slightly smaller circle for a cleaner look */
  height: 100px;
  position: relative;
  margin: 0 auto;
}

/* Force text inside the big circle to a smaller size AND darker color */
.bigCircle .CircularProgressbar-text {
  font-size: 12px;
  fill: #424242 !important; /* override possible defaults */
}

/* Simple tooltip for the big circle */
.objTooltip {
  background: rgba(66,66,66, 0.9);
  padding: 8px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 0.85rem;
  width: 110px;
  text-align: center;
  position: absolute;
  top: 108%;  /* slightly below the circle */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.bigCircle:hover .objTooltip {
  opacity: 1;
}

/* Vertical line between big circles & historical stack */
.verticalDivider {
  align-self: stretch;
  border-left: 2px solid #eeeeee;
  margin: 0;
}

/* Right side: stacked historical small circles */
.historicalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center children horizontally */
  gap: 6px;
  text-align: center;
}

/* Smaller heading for the historical block */
.historicalHeader {
  font-size: 0.8rem;
  color: #424242;
  font-weight: 500;
}

/* The small circles are stacked vertically */
.historicCircles {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

/* each item has label + small circle 
   Center them vertically via flex column */
.smallCircleItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin-left: 10px;
}

.historicLabel {
  font-size: 0.8rem;
  color: #616161;
  margin-bottom: 2px;
  margin-right: 5px;
}

/* The small circle for react-circular-progressbar */
.smallCircleWrapper {
  width: 50px;
  height: 50px;
}

/* Smaller text & forced color inside the small circle */
.smallCircleWrapper .CircularProgressbar-text {
  font-size: 11px;
  fill: #424242 !important;
}

/* If "NED", we show a custom fallback circle */
.smallCircleNED {
  width: 50px;
  height: 50px;
  background: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  font-weight: bold;
  font-size: 0.8em;
}
