/* StoreUserManagement.module.css */
.container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background: #f4f4f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.formGroup {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input,
.select {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.error {
  color: red;
  font-size: 12px;
}

.checkboxGroup {
  margin: 20px 0;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.checkboxGroup.disabled {
  background: #e0e0e0;
}

.checkboxGroup .groupTitle {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.checkboxGroup label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.submitBtn {
  width: 100%;
  padding: 10px;
  background: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.submitBtn:hover {
  background: #0056b3;
}
